import styled from "styled-components"
import { device } from "../devices"

const TitleSection = styled.h2`
  color: ${({ textColor }) => textColor || "var(--color-negro)"};
  font-weight: normal;
  font-size: 25px;
  margin-bottom: 1em;

  strong {
    font-weight: bold;
    color: ${({ highLightColor }) =>
    highLightColor || "var(--color-principal)"};
  }

  ${device.tablet} {
    font-size: 40px;
  }

  ${device.desktop} {
    font-size: 80px;
  }
`
export { TitleSection }
