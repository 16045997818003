import React from "react"
import { Title } from "./title"
import { Text } from "./text"

const Item = ({ title, text }) => {
  return (
    <div>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </div>
  )
}

export { Item }
