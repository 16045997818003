import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PromisesList = [
  {
    title: "Service Oriented",
    text: (
      <>
        <strong>
          We love the fresh fragrance of well given attention in the morning.
        </strong>{" "}
        We love to excel in service and help solving problems, treating you in a
        dignified and elevated way.
      </>
    ),
  },
  {
    title: "Alliance Seekers",
    text: (
      <>
        <strong>More than a transaction, we build a relationship.</strong> We
        care about your purpose and your goals, that's why we partner with you
        to achieve them together.
      </>
    ),
  },
  {
    title: "Empathic",
    text: (
      <>
        <strong>We are not satisfied with traditional practices.</strong> We
        design with a human-centered approach, researching deeply to create
        projects that make the company and society prosper.
      </>
    ),
  },
  {
    title: "Purposeful",
    text: (
      <>
        <strong>More than business, we can make a difference.</strong> Team up
        with us and join the fight for relevant causes, aligning your business
        vision with social impact purposes, thus increasing your relevance.
      </>
    ),
  },
]

const ServicesList = [
  {
    title: "Brand",
    info: "We design the brands of the present and future, helping you create your brand from the start, from an identity to a whole brand system that keeps you relevant. ",
    tags: [
      "Purpose Design",
      "Visual Identity",
      "Verbal Identity",
      "Digital Marketing",
      "Brand Campaigns",
    ],
  },
  {
    title: "Copy",
    info: "Anything that transcends, needs to be communicated. We conceptualize and write in a way that convinces and inspires. Oh we love to write.",
    tags: ["Concept Development", "Content creation", "Naming", "Editing"],
  },
  {
    title: "Digital",
    info: "We develop optimized digital projects, with great message, impressive looks and solid structures, designed to be intuitive and attractive.",
    tags: [
      "Web Development",
      "UX / UI Design",
      "Digital Systems",
      "Application Software",
    ],
  },
  {
    title: "Design",
    info: "We master the craft of visual communication in an era that connects through images and design. We help your message to stand out and be clear.",
    tags: ["Visual Design", "Illustration", "Editorial", "Data Design"],
  },
  {
    title: "Media",
    info: "We capture and create narratives through our lenses and microphones, then, we put them in the right order to design appealing stories that inspire and connect.",
    tags: ["Photography Production", "Video Production", "Podcast"],
  },
]

const TeamMembersList = [
  {
    image: (
      <StaticImage
        src="../images/TEAM-DanielaDuenas.jpg"
        alt="Daniela Dueñas"
      />
    ),
    name: "Daniela Dueñas",
    title: "CEO & Partner",
    instagram: "https://www.instagram.com/daniduenasr/",
    linkedin: "https://www.linkedin.com/in/daniela-due%C3%B1as-061b581b4/",

  },
  {
    image: (
      <StaticImage
        src="../images/TEAM-DenisseCarrillo.jpg"
        alt="Denisse Carrillo"
      />
    ),
    name: "Denisse Carrillo",
    title: "Art Director & Partner",
    instagram: "https://www.instagram.com/lechonrosa/",
    linkedin: "https://www.linkedin.com/in/denisse-carrillo-10811453/",
  },
  {
    image: <StaticImage src="../images/TEAM-JorgeSosa.jpg" alt="Jorge Sosa" />,
    name: "Jorge Sosa",
    title: "Creative Director & Partner",
    instagram: "https://www.instagram.com/jorgesosa.av/",
    linkedin: "https://www.linkedin.com/in/jorge-arturo-sosa-mart%C3%ADnez-910a2218a/",
  },
  {
    image: (
      <StaticImage
        src="../images/TEAM-AbdielVillegas.jpg"
        alt="Abdiel Villegas"
      />
    ),
    name: "Abdiel Villegas",
    title: "Head of Operations",
    instagram: "https://www.instagram.com/abdielvx/",
    linkedin: "https://www.linkedin.com/in/abdiel-hernandez-villegas-1a4286172/",
  },
]

const ProjectsList = [
  {
    image: <StaticImage src="../images/PROJECT-1.png" alt="Jesus Love Phily" />,
    project: "Jesus Love Phily",
  },
  {
    image: <StaticImage src="../images/PROJECT-2.png" alt="Helpology" />,
    project: "Helpology",
  },
  {
    image: <StaticImage src="../images/PROJECT-3.png" alt="Nekhi" />,
    project: "Nekhi",
  },
  {
    image: <StaticImage src="../images/PROJECT-4.png" alt="Refabulare" />,
    project: "Refabulare",
  },
  {
    image: <StaticImage src="../images/PROJECT-5.png" alt="AIVIA" />,
    project: "AIVIA",
  },
]

export { PromisesList, ServicesList, TeamMembersList, ProjectsList }
