import styled from "styled-components"
import { device } from "../devices"

const Name = styled.h3`
  color: var(--color-negro);
  font-size: 18px;

  ${device.tablet} {
    font-size: 20px;
  }

  ${device.desktop} {
    font-size: 22px;
  }
`

export { Name }
