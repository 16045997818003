import React from "react"
import TeamCards from "../TeamCards"
import { Container } from "../Utilities/container"
import { Section } from "../Utilities/section"
import { TitleSection } from "../Utilities/titleSection"
import { TitleWithIcon } from "../Utilities/titleWithIcon"
import { TeamMembersList } from "../homeLists"

const Team = () => {
  return (
    <Section backgroundColor="#EAE8E4">
      <Container>
        <TitleWithIcon iconColor="var(--color-principal)">
          Our People
        </TitleWithIcon>
        <TitleSection>
          The <strong>dream team </strong>that makes{" "}
          <strong>dreams real.</strong>
        </TitleSection>
        <TeamCards members={TeamMembersList} />
      </Container>
    </Section>
  )
}

export default Team
