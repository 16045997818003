import styled from 'styled-components'
import { device } from "../devices"

const Info = styled.div`
  font-size: 20px;
  display: ${({ show }) => (show ? "block" : "none")};
  color: white;

  p {
    margin-bottom: 30px;
    line-height: 1.5;
  }

  ${device.tablet} {
    font-size: 25px;

    p {
      margin-bottom: 50px;
    }
  }

  ${device.desktop} {
    font-size: 30px;

    p {
      margin-bottom: 70px;
    }
  }
`

export { Info }