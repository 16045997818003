import styled from 'styled-components'
import { device } from '../devices'

const Title = styled.h3`
  color: var(--color-promesas);
  font-size: 20px;
  margin-bottom: 30px;

  ${device.tablet} {
    font-size: 35px;
    margin-bottom: 40px;
  }

  ${device.desktop} {
    font-size: 65px;
    margin-bottom: 40px;
  }
`

export { Title }