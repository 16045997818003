import React from "react"
import { Grid } from "./grid"
import { Item } from "./item"

const Promises = ({ promisesList }) => {
  return (
    <Grid>
      {promisesList.map(({ title, text }, index) => (
        <Item key={index} title={title} text={text} />
      ))}
    </Grid>
  )
}

export default Promises
