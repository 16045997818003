import styled from "styled-components"
import { device } from "../devices"

const Member = styled.div`
  display: flex;
  flex-direction: column;

  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }

  ${device.tablet} {
    margin-bottom: 30px;
  }
`

export { Member }
