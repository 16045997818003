import React, { useState } from "react"
import { Info } from "./info"
import { Menu, MenuItem } from "./menu"
import { ServicesWrapper } from "./servicesWrapper"
import { Tags } from "./tags"

const Services = ({ services }) => {
  const [serviceToShow, setServiceToShow] = useState(0)
  
  return (
    <ServicesWrapper>
      <Menu>
        {services.map((service, index) => (
          <MenuItem
            active={serviceToShow === index}
            key={index}
            onClick={() => setServiceToShow(index)}
          >
            {service.title}
          </MenuItem>
        ))}
      </Menu>

      <div>
        {services.map((service, index) => (
          <Info key={index} show={serviceToShow === index}>
            <p>
              {service.info}
            </p>

            <Tags>
              {service.tags.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
            </Tags>
          </Info>
        ))}
      </div>
    </ServicesWrapper>
  )
}

export default Services
