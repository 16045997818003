import React from "react"
import { SocialLink, SocialRow } from "../Utilities/social"
import { Grid } from "./grid"
import { Member } from "./member"
import { Name } from "./name"
import { Title } from "./title"
import { FaInstagram, FaLinkedinIn } from "react-icons/fa"


const TeamCards = ({ members }) => {
  return (
    <Grid>
      {members.map((member, index) => (
        <Member key={index}>
          {member.image}
          <Name>{member.name}</Name>
          <Title>{member.title}</Title>
          <SocialRow>
            <SocialLink href={member.instagram} target="_blank" variant="rounded" fontSize="20px">
              <FaInstagram />
            </SocialLink>

            <SocialLink href={member.linkedin} target="_blank" variant="rounded" fontSize="16px">
              <FaLinkedinIn />
            </SocialLink>
          </SocialRow>
        </Member>
      ))}
    </Grid>
  )
}

export default TeamCards
