
import React from 'react'
import styled from 'styled-components'
import { device } from '../devices'
import { StaticImage } from "gatsby-plugin-image"
import { Section } from '../Utilities/section'
import { Container } from '../Utilities/container'
import { TitleWithIcon } from '../Utilities/titleWithIcon'
import { TitleSection } from '../Utilities/titleSection'

const WhatWeDoSection = () => {
  return (
    <SectionNoPaddingBottom>
      <Container>
        <TitleWithIcon>What we do</TitleWithIcon>
        <TitleSection>
          We create <strong>meaningful brands</strong> using <strong>design and creative communication,</strong> driven by <strong>purpose and innovation.</strong>
        </TitleSection>
      </Container>

      <div className="image">
        <StaticImage src="../../images/What-we-do.png" alt="Brand" />
      </div>
    </SectionNoPaddingBottom>
  )
}

const SectionNoPaddingBottom = styled(Section)`
  padding-bottom: 0;

  .image {
    margin-top: 50px;
    text-align: center;
  }

  ${device.tablet} {
    padding-bottom: 0;
  }

  ${device.desktop} {
    padding-bottom: 0;
  }
`

export default WhatWeDoSection