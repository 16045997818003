import styled from 'styled-components'

const Tags = styled.ul`
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-left: 30px;

  li {
    margin: 15px 0;
  }
`

export { Tags }