import styled from "styled-components"
import { device } from "../devices"

const PlainText = styled.p`
  font-size: 20px;
  color: ${({ textColor }) => textColor || "var(--color-negro)"};
  line-height: 1.5;
  
  strong {
    font-weight: bold;
  }
  
  ${device.tablet} {
    ${({ maxWidth }) => maxWidth && "max-width: " + maxWidth};
    font-size: 25px;
  }

  ${device.desktop} {
    font-size: 30px;
  }
`
export { PlainText }
