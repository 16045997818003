import { BgImage } from "gbimage-bridge"
import React from "react"
import styled from "styled-components"
import { device } from "../devices"
import { Container } from "../Utilities/container"
import { PlainText } from "../Utilities/plainText"
import { TitleSection } from "../Utilities/titleSection"

const LetsTalk = (props) => {
  return (
    <BackgroundImageSection backgroundColor="var(--color-negro)" {...props}>
      <Container>
        <TitleSection textColor="var(--color-blanco)">
          We’re always up for talking through{" "}
          <strong>how we can help.</strong>
        </TitleSection>
        <PlainText textColor="var(--color-blanco)" maxWidth="54%">
          If you’d like to speak about your next project, email
          hey@lorogranada.com. Or you can give us a call on +52 (81) 2508 5724
        </PlainText>
      </Container>
    </BackgroundImageSection>
  )
}

const BackgroundImageSection = styled(BgImage)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-blanco)"};
  padding-top: 50px;
  padding-bottom: 50px;

  ${device.tablet} {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  ${device.laptop} {
    padding-top: 200px;
    padding-bottom: 200px;
  }
`

export default LetsTalk 