import styled from "styled-components"
import { device } from "../devices"

const Title = styled.h4`
  color: var(--color-gris);
  font-size: 16px;
  font-weight: bold;

  ${device.tablet} {
    font-size: 18px;
  }

  ${device.desktop} {
    font-size: 20px;
  }
`

export { Title }
