import styled from "styled-components"
import { device } from "../devices"

const Menu = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;

  ${device.tablet} {
    margin-bottom: 0;
  }
`

const MenuItem = styled.li`
  display: flex;
  color: ${({ active }) => (active ? "var(--color-blanco)" : "#2D2D2D")};
  padding: 10px 0;
  cursor: pointer;
  font-size: 40px;
  font-weight: bold;
  transition: color 0.3s;
  
  ${device.tablet} {
    font-size: 60px;
    justify-content: left;
  }

  ${device.desktop} {
    font-size: 80px;
    justify-content: left;
  }
`

export { Menu, MenuItem }