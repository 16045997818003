import styled from "styled-components"
import { device } from "../devices"

const TitleWithIcon = styled.h2`
  align-items: center;
  color: ${({ textColor }) => textColor || "var(--color-negro)"};
  display: flex;
  font-size: 18px;
  margin-bottom: 30px;

  ::before {
    border-radius: 50%;
    border: 3px solid
      ${({ iconColor }) => iconColor || "var(--color-principal)"};
    content: "";
    display: inline-block;
    height: 25px;
    margin-right: 15px;
    width: 25px;
  }

  ${device.tablet} {
    font-size: 22px;
    margin-bottom: 60px;

    ::before {
      height: 30px;
      width: 30px;
    }
  }

  ${device.desktop} {
    font-size: 30px;
    margin-bottom: 90px;

    ::before {
      height: 35px;
      width: 35px;
    }
  }
`

export { TitleWithIcon }
