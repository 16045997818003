import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/Utilities/seo"
import Hero from "../components/Hero"
import TypewriterComponent from "typewriter-effect"
import {
  ProjectsSection,
  PromisesSection,
  PurposeSection,
  ServicesSection,
  Team,
  WhatWeDoSection,
  LetsTalk,
} from "../components/HomeSections"

const Index = ({ data }) => {
  const heroImage = getImage(data.heroImage)
  const servicesImage = getImage(data.servicesImage)
  const purposeImage = getImage(data.purposeImage)
  const letsTalkImage = getImage(data.letsTalkImage)

  return (
    <>
      <Seo title="Inicio" />
      <Hero image={heroImage}>
        We are
        <TypewriterComponent
          options={{
            strings: [
              "Rebel creators",
              "Purpose Designers",
              "Digital Activists",
              "Creative Allies",
            ],
            autoStart: true,
            loop: true,
          }}
        />
      </Hero>

      <WhatWeDoSection />

      <ServicesSection id="services" image={servicesImage} />

      {/* <ProjectsSection /> */}

      <PromisesSection />

      <Team />

      <PurposeSection image={purposeImage} />

      <LetsTalk id="contact" image={letsTalkImage} />
    </>
  )
}

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3840
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    servicesImage: file(relativePath: { eq: "ServicesSection.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3840
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    purposeImage: file(relativePath: { eq: "PurposeSection.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3840
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    letsTalkImage: file(relativePath: { eq: "LetsTalk-background.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3840
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default Index
