import styled from "styled-components"
import { device } from "../devices"

const Title = styled.h2`
  color: var(--color-blanco);
  font-size: clamp(60px, 15vw, 110px);
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;

  .Typewriter {
    font-size: clamp(25px, 9vw, 100px);
    -webkit-text-fill-color: var(--color-blanco);
    -webkit-text-stroke: 0;
  }

  ${device.tablet} {
    -webkit-text-stroke: 2px;

    .Typewriter {
      -webkit-text-stroke: 0;
    }
  }
`

export { Title }
