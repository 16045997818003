import React from "react"
import { Container } from "../Utilities/container"
import { TitleWithIcon } from "../Utilities/titleWithIcon"
import { PlainText } from "../Utilities/plainText"
import { TitleSection } from "../Utilities/titleSection"
import { device } from "../devices"
import { BgImage } from "gbimage-bridge"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const PurposeSection = (props) => {
  return (
    <>
      <OnlyMobileImage>
        <StaticImage src="../../images/PurposeSectionHorizontal.png" alt="Hojas de plantas" />
      </OnlyMobileImage>
      <BackgroundImageSection backgroundColor="#DBB045" {...props}>
        <Container>
          <TitleWithIcon
            iconColor="var(--color-negro)"
            textColor="var(--color-blanco)"
          >
            Driven by
          </TitleWithIcon>
          <TitleSection color="var(--color-negro)" highLightColor="var(--color-negro)">
            <strong>Purpose.</strong>
          </TitleSection>
          <PlainText textColor="var(--color-blanco)" maxWidth={"58%"}>
            We are moved by helping brands and disruptors to create positive
            change. We believe in the power of creativity and social
            responsibility to move us towards innovation.
          </PlainText>
          <PurposeListContainer>
            <PurposeList>
              <li>Faith</li>
              <li>Empathy</li>
              <li>Creativity</li>
            </PurposeList>
            <PurposeList>
              <li>Love</li>
              <li>Courage</li>
              <li>Integrity</li>
            </PurposeList>
          </PurposeListContainer>
        </Container>
      </BackgroundImageSection>
    </>
  )
}

const OnlyMobileImage = styled.div`
  display: block;

  div {
    width: 100%;
  }

  ${device.tablet} {
    display: none;
  }
`

const PurposeListContainer = styled.div` 
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  
  ${device.tablet} {
    padding-top: 50px;
    gap: 100px;
  }
  
  ${device.laptop} {
    gap: 200px;
  }
`

const PurposeList = styled.ul`
  list-style: none;
  padding: 0;
  font-weight: bold;

  li {
    font-size: 20px;
    margin-bottom: 10px;
    
    ${device.tablet} {
      font-size: 25px;
    }

    ${device.desktop} {
      font-size: 30px;
    }
  }
`

const BackgroundImageSection = styled(BgImage)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-blanco)"};
  padding-top: 50px;
  padding-bottom: 50px;
  
  ::before,
  ::after {
    content: none !important;
    background-size: 300px 100% !important;
    background-position: center right !important;
  }

  ${device.tablet} {
    padding-top: 70px;
    padding-bottom: 70px;

    ::before,
    ::after {
      content: ' ' !important;
    }
  }

  ${device.laptop} {
    padding-top: 150px;
    padding-bottom: 150px;

    ::before,
    ::after {
      background-size: 30% 100% !important;
    }
  }
`

export default PurposeSection
