import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { HardSection } from "../Utilities/section"
import { Container } from "../Utilities/container"
import { TitleWithIcon } from "../Utilities/titleWithIcon"
import Promises from "../Promises"
import { PromisesList } from "../homeLists"

const PromisesSection = () => {
  return (
    <HardSection backgroundColor="#000">
      <div className="image-container">
        <StaticImage
          src="../../images/PromiseSection.png"
          alt="Hojas de plantas"
        />
      </div>
      <Container>
        <TitleWithIcon
          textColor="var(--color-blanco)"
          iconColor="var(--color-promesas)"
        >
          Responsible Creativity Promise
        </TitleWithIcon>

        <Promises promisesList={PromisesList} />
      </Container>
    </HardSection>
  )
}

export default PromisesSection
