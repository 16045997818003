import React, { useEffect, useState } from "react"
import { Container } from "../Utilities/container";
import { HeroBlock } from "./heroBlock";
import { Title } from "./title";

const Hero = ({ children, ...props }) => {
  const colorList = ["#00c6ff", "#ff7200", "#c600ff", "#ff0090", "#ffea00", "#00ffcc"]
  const [listIndex, setListIndex] = useState(0)
  const [color, setColor] = useState(colorList[listIndex])

  const shuffle = () => {
    setColor(colorList[listIndex])

    listIndex === colorList.length - 1 ? setListIndex(0) : setListIndex(listIndex + 1)
  }

  useEffect(() => {

    const interval = setInterval(shuffle, 3000);

    return () => clearInterval(interval);

  }, [listIndex]);


  return (
    <HeroBlock {...props} backgroundColor={color}>
      <Container>
        <Title>
          {children}
        </Title>
      </Container>
    </HeroBlock>
  )
}

export default Hero