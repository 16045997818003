import styled from 'styled-components'
import { device } from '../devices'

const ServicesWrapper = styled.div`
  align-items: center;
  font-size: 18px;

  ${device.tablet} {
    display: grid;
    font-size: 20px;
    grid-template-columns: 1fr 1fr;
  }

  ${device.desktop} {
    font-size: 22px;
  }
`

export { ServicesWrapper }