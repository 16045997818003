import styled from "styled-components"
import { device } from "../devices"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px 0;

  ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 60px 30px;
  }
  
  ${device.laptop} {
    gap: 60px;
  }
  
  ${device.desktop} {
    gap: 60px 130px;
  }
`

export { Grid }