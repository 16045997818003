import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "../devices"

const Button = styled(Link)`
  cursor: pointer;
  display: inline-block;
  background-color: ${({ buttonColor }) =>
    buttonColor || "var(--color-blanco)"};
  border-radius: 2em;
  border: 2px solid var(--color-blanco);
  color: ${({ textColor }) => textColor || "var(--color-negro)"};
  font-weight: bold;
  margin-top: 0px;
  margin: 0 auto;
  padding: 3px 25px 5px;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: var(--color-principal);
    border-color: var(--color-principal);
  }

  ${device.tablet} {
  }

  ${device.desktop} {
  }
`
export { Button }
