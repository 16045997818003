import { StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"
import styled from "styled-components"
import { device } from "../devices"
import { ServicesList } from "../homeLists"
import Services from "../Services"
import { Container } from "../Utilities/container"
import { TitleWithIcon } from "../Utilities/titleWithIcon"

const ServicesSection = (props) => {
  return (
    <>
      <OnlyMobileImage>
        <StaticImage src="../../images/ServicesSectionHorizontal.png" alt="Hojas de plantas" />
      </OnlyMobileImage>
      <BackgroundImageSection backgroundColor="#601DD3" {...props}>
        <Container>
          <TitleWithIcon
            textColor="var(--color-blanco)"
            iconColor="var(--color-blanco)"
          >
            Services
          </TitleWithIcon>

          <Services services={ServicesList} />
        </Container>
      </BackgroundImageSection>
    </>
  )
}

const OnlyMobileImage = styled.div`
  display: block;

  ${device.tablet} {
    display: none;
  }
`

const BackgroundImageSection = styled(BgImage)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-blanco)"};
  padding-top: 50px;
  padding-bottom: 50px;

  ::before,
  ::after {
    content: none !important;
    background-size: 300px 100% !important;
    background-position: center right !important;
  }

  ${device.tablet} {
    padding-top: 70px;
    padding-bottom: 70px;

    ::before,
    ::after {
      content: ' ' !important;
    }
  }

  ${device.laptop} {
    padding-top: 150px;
    padding-bottom: 150px;

    ::before,
    ::after {
      background-size: 30% 100% !important;
    }
  }
`

export default ServicesSection
