import styled from 'styled-components'
import { device } from '../devices'

const Text = styled.p`
  color: var(--color-blanco);
  font-size: 16px;
  line-height: 1.5;

  ${device.tablet} {
    font-size: 18px;
  }

  ${device.desktop} {
    font-size: 20px;
  }
`

export { Text }
